@import 'app/shared/styles/mixins';
@import 'app/shared/styles/button';
@import 'app/shared/styles/expansion-panel.scss';
@import 'app/shared/styles/form';
@import 'app/shared/styles/sticky-header';
@import 'app/shared/styles/sidenav';

$primary-color: #461e7d;
$secondary-color: #5738ff;
$gray: #888888;

ngx-global-contact-footer {
  display: block;
  padding: 40px 25px 25px;
  a {
    margin: inherit;
    color: var(--link-color);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
}

body {
  margin: 0;
}

.cdk-overlay-backdrop {
  z-index: 0 !important;
}

.cdk-virtual-scroll-viewport::-webkit-scrollbar {
  display: none;
}

.cdk-virtual-scroll-viewport {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-label-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

lib-ngx-deeplinker {
  ::ng-deep button {
    background-color: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 6px 10px !important;
    height: 26px !important;
    align-self: flex-end;
  }
}

.mobile-only {
  display: none;
}
.desktop-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

.tooltip-btn-wrapper {
  display: inline-block;
}

.profile-button-wrapper {
  height: 38px;
}

.mat-mdc-tooltip {
  background: rgba(0, 0, 0, 0.7);
  font-size: 13px;
}

@media screen and (max-width: 520px) {
  ::ng-deep .mat-mdc-form-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-subscript-wrapper {
      position: static;
    }
  }
  lib-ngx-deeplinker {
    ::ng-deep button {
      height: unset !important;
    }
  }
}

.ng-invalid {
  color:var(--warn-color);
}

.ng-invalid .mat-focused {
  color:var(--primary-color);
}

.mat-form-field-appearance-outline {
  --mdc-outlined-text-field-outline-color: #ccc;
  --mdc-outlined-text-field-hover-outline-color: var(--primary-color);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color);
}

.tree-control > mat-icon > svg > path {
  fill: var(--primary-color) !important;
}

cdk-virtual-scroll-viewport::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-track {
  background: var(--sidebar-bg-color); /* Color of the scrollbar track */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
  background: var(--primary-lighter-color); /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color); /* Color of the scrollbar thumb on hover */
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--primary-color) !important;
}

.mat-form-field-appearance-outline {
  --mdc-outlined-text-field-outline-color: #ccc;
  --mdc-outlined-text-field-hover-outline-color: var(--primary-color);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color);
}

.tree-node-margin {
  background-color: var(--primary-color) !important;
}

.tree-node:hover {
  background-color: var(--primary-lighter-color) !important;
}



// Kendo styles

.kendo-treeview ul li ul li {
  padding: 8px 0 5px 30px;
  width: 40%;
}

.kendo-equipmentGroup-tree ul li ul li:nth-child(odd) {
  background: var(--primary-lighter-color);
}

.kendoChildList ul li ul li:nth-child(odd) {
  background-color: var(--primary-lighter-color);

}

.kendoChildList {
  margin-left: 55px;
}


.k-treeview-leaf-text > mat-icon {
  vertical-align: middle;
}

.tooltip-icon {
  color: var(--primary-color);
  font-size: 24px;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  margin-right: 10px;
}

// Additional placeholder for invalid mat-form-fields
// src: https://github.com/angular/material2/issues/4580

mat-form-field.ng-invalid.ng-touched {
  animation: error;
  animation-duration: 0.3s;
  margin-bottom: 2.5rem;
}

@keyframes error {
  from {margin-bottom: 0;}
  to {margin-bottom: 2.5rem;}
}
